//Home.js
import React, {useEffect, useMemo, useState} from 'react';
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useNavigate, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import Login from "./Login";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Calculator from "./components/Calculator";
import ConnectionError from "./components/ConnectionError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { EyeFill} from '@styled-icons/bootstrap/EyeFill'
import {fix_page_contents_class_height} from "./Scripts";
import PurpleLoader from './components/PurpleLoader';
import {DevTool} from "@hookform/devtools";
import PageLoading from "./components/PageLoading";


const ico_height = '25px';

const EyeIco = styled(EyeFill)`
      height:${ico_height}
    `;

const CreateProject = () => {

    function validate_youtube_embed(url){
        console.log(url);

        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

        var match = url.match(regExp);

        if (match ) {

            SetEmbedVideoID(match[8]);




            return match;
        } else {
            return false;
        }



    }



    function validate_vimeo_embed(url){
        console.log(url);
        var regExp = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
        var match = url.match(regExp);
        if (match ) {

            SetEmbedVideoID(match[4]);


            return match;
        } else {
            return false;
        }

    }



    const { project_id } = useParams();


    const { register,trigger, control,handleSubmit, formState: { errors } } = useForm();

    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);
    useEffect(() => {
        scripts.fix_page_contents_class_height();

    },[]);

    const [uploaded_file_data, SetUploadedFileData] = useState();


    useEffect(() => {



        if(uploaded_file_data){

            try {

            $('#muf_id').val(uploaded_file_data.muf_id);
            }
            catch(err){

            }

            trigger('upload_file');



        }

    },[uploaded_file_data]);



    const [media_contents, SetMediaContents] = useState([
    get_base_media_empty_content()
    ]);

    const [med_opt, SetMedOpt] = useState("");

    const [lead_text, SetLeadText] = useState("");
    const [end_text, SetEndText] = useState("");
    const [embed_text, SetEmbedText] = useState("");
    const [embed_videoid, SetEmbedVideoID] = useState("");


    useEffect(() => {

        calculate_total_pricing();


    },[media_contents]);


        useEffect(() => {

        if(embed_text){

            setTimeout(function(){
                trigger("embed_text");

            },1);

        }
    },[embed_text]);







    useEffect(() => {

        if(med_opt){

            setTimeout(function(){
                show_hide_file_upload(med_opt);

            },1);

        }
    },[med_opt]);



    const [is_file_being_uploaded, SetIsFileBeingUploaded] = useState(false);

    const [uploading_file_index, SetUploadingFileIndex] = useState(0);


    const [is_submitting, SetSubmitting] = useState(false);
    const [data, setData] = useState(0);
    const [country_code, setCountrycode] = useState('');


    const [templates, setTemplates] = useState([]);
    const [template_id, TemplateIdSet] = useState('');

    const [template_data, SetTemplateData] = useState('');
    const [template_name, SetTemplateName] = useState('');

    const [product_price, setProductprice] = useState(0);

    const [product_id, setProductId] = useState(0);
    const [completes_options, setCompleteoptions] = useState([]);


    const [languages, setLanguages] = useState([]);

    const [project_title, SetProjectTitle] = useState('');


    const [number_of_completes, setCompletes] = useState('');

    const [verification_type, setVerificationtype] = useState(0);

    var countries;

    useEffect(() => {

        if (country_code=='') {
            return;
        }


        set_template_select_values();

    },[country_code]);


    useEffect(() => {

        if(!data){
            return;
        }
            console.log("find_lang");
            trigger_country_change();

    },[data]);

        useEffect(() => {
        if(!product_id){
            return;
        }
        trigger('select_product');
        calculate_total_pricing();


    },[number_of_completes]);


    useEffect(() => {
        if(!product_id){
            return;
        }
            set_template_select_values();
            calculate_total_pricing();
            get_number_of_completes_options();




    },[product_id]);


    const [category_id, setCatId] = useState(0);

    useEffect(() => {
        if(!category_id){
            return;
        }
        trigger('category');



        set_template_select_values();
    },[category_id]);





    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // let {id} = useParams();

    useEffect(() => {

        fetchData(project_id);
    }, []);


    const fetchData = async (project_id) => {
        try {
            const response = await fetch(window.api_url+'adtest/main/get_adtest_main_general_data?'+scripts.get_api_url_suffix()+(project_id ? "&project_id="+project_id : "")); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();


            if(data.adverts){
                SetMediaContents(data.adverts);
            }
            if(data.file_data){

                $('#muf_id').val(data.file_data.muf_id);

                SetUploadedFileData(data.file_data);

            }


            if(data.media_type){

                SetMedOpt(data.media_type);

            }
            if(data.lead_text){
                console.log("set_go");
                SetLeadText(data.lead_text);
            }

            if(data.embed_text){
                SetEmbedText(data.embed_text);
            }


            if(data.amount_left_to_pay){
                console.log("set_amount");
                setProductprice(data.amount_left_to_pay);
            }

            if(data.project_title) {
                SetProjectTitle(data.project_title);
            }

                if(data.complete_options){
                console.log("asdasdasd");
                    setCompleteoptions(data.complete_options);
                 }

            if(data.sub_total){

                setCompletes(data.sub_total);
            }


            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    async function calculate_total_pricing(){


        try {

            var url;

            var url = window.api_url+'adtest/main/get_pricing?product_id='+product_id+'&country_code='+country_code;





            if(number_of_completes){
                url+="&number_of_completes="+number_of_completes;
            }



            if($('#ver_email').is(":checked")){
                url+="&email_verification=1";
            }


            if($('#ver_sms').is(":checked")){
                url+="&sms_verification=1";
            }

            if(project_id){

                var url = window.api_url+'adtest/main/get_project_pricing?project_id='+project_id;


            }

            var num_adverts = media_contents.length;

            url+="&num_adverts="+num_adverts;

            url+='&'+scripts.get_api_url_suffix();

            const response =  await fetch(url); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

                setProductprice(data.price);
            $('.estimated_cost').css('display','block');



        } catch (error) {
            console.log(error);
        }




    }
    async function get_number_of_completes_options(){
        try {

            const response =  await fetch(window.api_url+'adtest/main/get_product_completes_options?product_id='+product_id+'&'+scripts.get_api_url_suffix()); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

            setCompleteoptions(data);
            setCompletes(data[0].num_completes);

        } catch (error) {
            console.log(error);
        }
    }
    function select_product(in_product_id) {









        setProductId(in_product_id);




        $('.choose_product li').removeClass('active');

        $('.choose_product li.product'+in_product_id).addClass('active');


    }


    async function set_template_select_values(){


        console.log("cat id : "+category_id);

        if(category_id==1){

            $('input[name="ver_opt"]').prop("checked",false);
        } else {
            $('#ver_none').prop("checked",true);

        }

        console.log("prod id : "+product_id);

        if(!category_id || !product_id){
            return;
        }

        try {




            const response =  await fetch(window.api_url+'adtest/main/get_templates_by_product_and_category?country_code='+country_code+'&product_id='+product_id+'&category_id='+category_id+"&"+scripts.get_api_url_suffix()); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

            console.log(data);

            if(data.length==0){
                var new_arr = [{sid:0,surveyls_title:""}];

                setTemplates(new_arr);
            }

            if(data.length){
            var new_arr = [{sid:0,surveyls_title:""},...data];

                setTemplates(new_arr);
            }

        } catch (error) {
            console.log(error);
        }


    }

  async  function set_template_values(event) {


        setCatId(event.target.value);

        $('div.name_type').css('display','block');

        if(event.target.value=="1"){
        $('div.opponent_name_type').css('display','block');
            //we're doing politics
            //show verification
            $('.verification_type').addClass('show');
            $('#name_type_label').text("Candidate Name");
        } else {
            $('div.opponent_name_type').css('display','none');
            //we're doing brands
            $('#name_type_label').text("Brand Name");

            $('.verification_type').removeClass('show');
            //hide verification

        }
        console.log("cat_trig");



    set_template_select_values();





    }

    function set_number_of_completes(event) {
        setCompletes(event.target.value);



        //In case of editing a project we recalculate pricing on completes change
            if(project_id){



                $.post(window.api_url+'adtest/main/adtest_get_new_pricing_for_edit_setup?project_id='+project_id+'&'+scripts.get_api_url_suffix(),{

                    num_completes: event.target.value
                    },
                    function(data){

                        if(data.new_price){


                            setProductprice(data.new_price);

                        }

                    },'json').fail(function(error){
                    SetSubmitting(false);



                    try {
                    alert(error.responseJSON.error);

                    }
                    catch(err){
                        alert("connnection error");
                    }

                });
            }
    }

    function countrychange() {
        console.log("country_changed");

        trigger_country_change();



    }
    function find_lang_in_countries(){


        var select_country = $('#country').val();

        var value_found;
        data.countries.forEach(function(country){
           if(country.code==select_country){


                    value_found = country.languages;

                    setCountrycode(country.alpha2);

           }
        });


        return value_found;


    }
    function trigger_country_change(){



        var new_lang = find_lang_in_countries();

        setLanguages(new_lang);
    }

    function show_hide_file_upload(event) {



        $('.label_wrapper.text_embed textarea').val('');


        try {
        var value = event.target.value;

        }
        catch(err){
        var value = event;
        }


        // $('.file_upload_selected').css('display','none');


            $('.media_contents').css('display','block');


            if(value=='embed'){
                SetLeadText("Please review this advert and click continue to proceed to the next step.");

            }
            if(value=='none'){
                SetLeadText("After you view this advert, the CONTINUE button will appear and we'll ask you some questions about it.");

            }

        if(value=='media'){



            SetLeadText("Please review this advert and click continue to proceed to the next step.");

            // $('#file_uploader').css('display','block');
            // $('.text_below_image').css('display','block');
            // $('.label_wrapper.text_embed').css('display','none');
            SetEmbedText("");

        }
        else if(value=='embed'){


            SetUploadedFileData({muf_id:""});

            SetLeadText("Please review this advert and click continue to proceed to the next step.");

            $('#file_upload').val(null);
            $('#muf_id').val("");


            // $('.label_wrapper.text_embed').css('display','block');

            $('.text_below_image').css('display','block');


        }
        else {
            SetUploadedFileData({muf_id:""});
            // $('.label_wrapper.text_embed').css('display','none');
            $('#file_upload').val(null);
            $('#muf_id').val("");



        }



        trigger('med_opt');

    }

    function OnSubmit() {



        SetSubmitting(true);
        const data = new FormData($('#createfrm')[0]);


        try {
        data.delete("upload_file");

        }
        catch(err){

        }

        var my_url = window.api_url+'adtest/main/adtest_create_survey_stage_one'+"?"+scripts.get_api_url_suffix();

        if(project_id){

            var num_adverts = media_contents.length;

            my_url = window.api_url+'adtest/main/adtest_update_survey_title_and_completions?project_id='+project_id+"&num_adverts="+num_adverts+"&"+scripts.get_api_url_suffix();

        }


        $.ajax({
            url: my_url,
            type: 'post',
            data: data,
            contentType: false,
            processData: false,
            success: function(response){
                SetSubmitting(false);

                try {
                var json = $.parseJSON(response);

                    if(project_id){

                        if(json.success){
                            navigate('/confirmation/'+project_id);

                        }

                    } else
                    if(json.new_sid){
                        navigate('/editproject/'+json.new_sid);

                    }

                }
                catch(err){
                    console.log(err);
                }

            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmitting(false);
                alert("internet_connnection_problem");


            }
        });


    }
    const uploadFile = (files,index) => {
        console.log("Uploading file...");
        const API_ENDPOINT = window.api_url+'adtest/main/upload_media_file_uploader'+"?"+scripts.get_api_url_suffix();
        const request = new XMLHttpRequest();
        const formData = new FormData();

        request.open("POST", API_ENDPOINT, true);
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {

                try {
                    var json = $.parseJSON(request.responseText);

                    SetUploadedFileData(json);

                    set_media_content_with_name_at_idx("uploaded_file_data",index,json);


                    SetIsFileBeingUploaded(false);

                    trigger('upload_file');


                }
                catch(err){

                }





            }
        };

        for (let i = 0; i < files.length; i++) {
            formData.append("myfile", files[i]);
        }
        request.send(formData);
    };
    async function upload_file(event,index) {
 try {
     const files = event.target.files;

     var file_name = files[0].name;

     var ext = file_name.substr(file_name.lastIndexOf('.') + 1);


     if (ext !== 'png' && ext !== 'PNG'
         && ext !== 'jpg' && ext !== 'JPG'
         && ext !== 'gif' && ext !== 'GIF'
         && ext !== 'MP4'
         && ext !== 'mp4'

     ) {

         alert('wrong_file_format');

         $("#file_upload").val(null);


     }

     // trigger('upload_file');

     SetUploadingFileIndex(index);

     SetIsFileBeingUploaded(true);

     uploadFile(files,index);
 }
 catch(err){

 }


    }

    function ver_opt_calculate_total_pricing() {
        trigger('ver_opt');
        calculate_total_pricing();
    }

    function set_med_opt(e) {
        SetMediaContents([get_base_media_empty_content()]);
        SetMedOpt(e.target.value);
    }

    function set_lead_text(e) {
        console.log("adasd");
        SetLeadText(e.target.value);
    }

    function is_string_valid_url(string) {


        var url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    function get_embed_from_text(embedded_text) {


        var url_entered = embedded_text;

        var vimeo_validate = validate_vimeo_embed(url_entered);

        //The function that is also implemented on the server and any change don't have this function or the service function requires to be changed on both


        if(vimeo_validate) {


            var vimeo_id = vimeo_validate[4];



            return '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/'+vimeo_id+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="4_5798765642752462208"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>';
        }


        var youtube_validate = validate_youtube_embed(url_entered);


        if(youtube_validate) {

            var youtube_id = youtube_validate[8];



            url_entered = "https://www.youtube.com/embed/"+youtube_id;


            console.log(url_entered);
            return '<iframe width="560" height="315" src="'+url_entered+'?si=ivpJ7gfzukRb9vrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>';

        }


    }

    function do_item_long_desc(product_id) {


        if(product_id==1){
            return <div className="product_content_center">
                <span className="prpl_v">✓</span> Target test by age, gender, location<br />
                <span className="prpl_v">✓</span> 4 x Custom Screener Questions<br />
                <span className="prpl_v">✓</span> Test- Video/ Image/ Text<br />
                <span className="prpl_v">✓</span> AI Data Mined Targeting from...<br />
                <span className="prpl_v">✓</span> Big Data compatible (inc Voter File)<br />
                <span className="prpl_v">✓</span> 100 Responses (150-300 options)<br />
                <span className="prpl_v">✓</span> Post Viewing- Emotion<br />
                <span className="prpl_v">✓</span> Post Viewing- Perception<br />
                <span className="prpl_v">✓</span> Post Viewing- Relevance<br />
                <span className="prpl_v">✓</span> Post Viewing- Action<br />
                <span className="prpl_v">✓</span> Likelihood to Share<br />
                <span className="prpl_v">✓</span> DEMOGRAPHICS- Age<br />
                <span className="prpl_v">✓</span> DEMOGRAPHICS- Gender<br />
                <span className="prpl_v">✓</span> DEMOGRAPHICS- Marital Status<br />
                <span className="prpl_v">✓</span> DEMOGRAPHICS- Ethnicity<br />
                <span className="prpl_v">✓</span> DEMOGRAPHICS- Spoken Language<br />
                <span className="prpl_v">✓</span> Location- Zip/State/Region/Division /County/DMA<br />
                <span className="prpl_v">✓</span> Devices Owned
            </div>
        } else
            if(product_id==2){
                return <div className="product_content_center">

                    <span className="prpl_v">✓</span> All Essential Plus...<br />
                    <span className="prpl_v">✓</span> Demographics- Has Kids (inc ages)<br />
                    <span className="prpl_v">✓</span> Demographics- Mom Type<br />
                    <span className="prpl_v">✓</span> 100 Responses (150-350 options)<br />
                    <span className="prpl_v">✓</span> Hobbies & Interests<br />
                    <span className="prpl_v">✓</span> Games Played<br />
                    <span className="prpl_v">✓</span> Movie Favorite Genres<br />
                    <span className="prpl_v">✓</span> Sport- Interests<br />
                    <span className="prpl_v">✓</span> TV Show- Format Preferences<br />
                    <span className="prpl_v">✓</span> Email or SMS verify (additional cost)<br />
                </div>
            }
            else
                if(product_id==3){
                    return <div className="product_content_center">
                        <span className="prpl_v">✓</span> All Essential, Plus  and...<br />
                        <span className="prpl_v">✓</span> Target test by Ethnicity, Marital Status, Employment, Children<br />
                        <span className="prpl_v">✓</span> 100 Responses (150-500 options)<br />
                        <span className="prpl_v">✓</span> Appstore/Google Play- Favorite App Types<br />
                        <span className="prpl_v">✓</span> News Sources-Favored<br />
                        <span className="prpl_v">✓</span> TV Networks- Watched<br />
                        <span className="prpl_v">✓</span> Websites- Most Visited<br />
                        <span className="prpl_v">✓</span> DEMOGRAPHICS- Main Grocery Store<br />
                        <span className="prpl_v">✓</span> DEMOGRAPHICS- Car Type<br />
                        <span className="prpl_v">✓</span> DEMOGRAPHICS- Current Life Events<br />
                    </div>
                }
    }

    function set_template_val() {
        alert("xxx");
    }

    function do_item_short_desc(product_id) {

        if(product_id==1){
            return <ul>
                <li>Ad Test</li>
                <li>Demographics</li>
                <li>Device Usage</li>
                <li>AI Summary & Targeting</li>
            </ul>

        }

        if(product_id==2){
            return <ul>
                <li>All Essential and</li>
                <li>Enhanced Demographics</li>
                <li>Hobbies & Interests</li>
            </ul>;
        }
        if(product_id==3){
            return <ul>
                <li>All Plus+ and</li>
                <li>Media Consumption</li>
                <li>Lifestyle</li>
            </ul>;
        }


    }


    var advert_num = function(index){
        return media_contents.length>1 ? <span className="adverachi">Advert #{index+1}</span> : <div></div>;

    }

    function set_media_content_with_name_at_idx(name, idx,value) {

        var new_media_contents = [...media_contents];

        var media_idx = 0;
        new_media_contents.forEach(function(media_item) {


            if(media_idx++==idx){
                media_item[name] = value;
            }


        });

        SetMediaContents(new_media_contents);


    }

    function get_base_media_empty_content() {
        return {
            end_text:"",
            lead_text:"After you view this advert, the CONTINUE button will appear and we'll ask you some questions about it.",
            advert_title:"",
            uploaded_file_data:{muf_id:"",file_type:""}
        };
    }

    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>{data.project_title ? "Setup - "+data.project_title : "Setup"}</h1>

                    <h4 className="page_sub_title">
                        <span className="">&nbsp;(Step 1 of 3)</span>
                    </h4>
                    <div className="page_contents_wrapper">


                        {loading ? (
                            <PageLoading />
                        ) : error ? (

            <ConnectionError />
                        ) : (

                                <div className="form createprojectfrm" onSubmit={handleSubmit(OnSubmit)} noValidate>
                                    <form id="createfrm" action="">
                                        <input type="hidden" name="country_code" value={country_code} />
                                        <input type="hidden" name="product_price" value={product_price} />

                                        <div className="select_country">

                                            {!project_id ?
                                                <div>
                                            <div className="label_wrapper col-6 first">
                                                <label htmlFor="country">Country</label>
                                                <select onChange={countrychange} name="country" id="country">
                                                    {data.countries.map((item) => (
                                                        <option key={item.code} value={item.code}>{item.langEN}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="label_wrapper col-6 first">
                                                <label htmlFor="language">Language</label>
                                                <select name="language" id="language">
                                                    {languages?.map((item) => (
                                                        <option key={item.cl_id} value={item.language}>{item.language}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                                </div>: ""}


                                        </div>


                                        <div className="label_wrapper">
                                            <label aria-invalid={errors.project_name ? "true" : "false"} htmlFor="project_name">Project Name</label>

                                            <div>
                                                <input

                                                    onChange={(e) => {


                                                                SetProjectTitle(e.target.value);
                                                    }}
                                                    defaultValue={project_title} id="project_name" type="text" name="project_name"  aria-invalid={errors.project_name ? "true" : "false"} {...register('project_name', { required: true})} />

                                                {errors.project_name && errors.project_name.type === "required" && <span
                                                    className="error_span">Project name is required</span>}

                                            </div>

                                        </div>




                                        {!project_id ?
     <div>
                                        <div className="choose_product">
                                            <h4><span>Choose a Product:</span></h4>
                                            <div {...register('select_product', {    validate: function(){if(!product_id) return 'false';}})}>
                                                {errors.select_product && <div
                                                    className="error_span">Please select a product</div>}
                                            </div>
                                            <input type="hidden" name="product_id" value={product_id} />
                                            <ul>
                                                {data.adtest_products.map((item) => (
                                                    <li key={item.product_id} className={'product_box '+'product'+item.product_id} key={item.product_id}>

                                                        <div className="inpadding">

                                                        <span className="icon"></span>
                                                        <div className="line_one">
                                                            {item.product_name}
                                                        </div>
                                                        <div className="line_two">
                                    From {item.price_txt}
                                                            </div>
                                                        <div className="line_three">
                                                            {do_item_short_desc(item.product_id)}
                                                        </div>

                                                        </div>
                                                        <div className="actions_lane">

                                                            <div className="centerer">
                                                            <Popup
                                                                trigger={<span className="readmore_product"><FontAwesomeIcon className="prpl" icon={faCircleInfo} /> View More </span>}
                                                                modal
                                                                nested
                                                            >
                                                                {close => (
                                                                    <div className="inmodal">
                                                                        <button className="close" onClick={close}>
                                                                            &times;
                                                                        </button>
                                                                        <div className="header"> {item.product_name} </div>
                                                                        <div className="content product_content">
                                                                            {do_item_long_desc(item.product_id)}
                                                                        </div>
                                                                        <div className="actions">

                                                                            <div className="in_actions">


                                                                            <button
                                                                                className="button select_product"
                                                                                onClick={() => {
                                                                                    console.log('modal closed ');
                                                                                    select_product(item.product_id);

                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Select Product
                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Popup>
                                                            <div className="clear"></div>
                                                            <a onClick={()=>select_product(item.product_id)} className="select_product">Select</a>

                                                            </div>
                                                        </div>

                                                    </li>
                                                ))}
                                            </ul>




                                        </div>

                                        <div className="select_template">

                                            <h4><span>Select Template:</span></h4>

                                            <div className="label_wrapper col-6 first">
                                                <label aria-invalid={errors.category ? "true" : "false"} htmlFor="category">Category</label>
                                                <select {...register('category', {    validate:function(value){if(!$('#category').val()) return 'no_value';}})} onChange={set_template_values} name="category" id="category"  aria-invalid={errors.category ? "true" : "false"}>
                                                    <option value=""></option>
                                                    {data.adtest_categories.map((item) => (
                                                        <option key={item.asc_id} value={item.asc_id}>{item.category_title}</option>
                                                    ))
                                                    }

                                                        </select>

                                                {errors.category && <span
                                                    className="error_span">Category is required</span>}

                                            </div>

                                            <div className="label_wrapper col-6">
                                                <label aria-invalid={errors.template ? "true" : "false"} htmlFor="template">Template

                                                    {template_id  ?
                                                        <Popup
                                                            onClose={() =>{
                                                                SetTemplateName("");
                                                                SetTemplateData("");
                                                            }}
                                                            onOpen={() => {


                                                                var ajax = $.ajax({
                                                                    url: window.api_url+'adtest/main/get_adtest_template_data?survey_id='+template_id+"&"+scripts.get_api_url_suffix(),
                                                                    type: 'get',
                                                                    contentType: false,
                                                                    processData: false,
                                                                    success: function(response){



                                                                        try {


                                                                            var data = $.parseJSON(response);

                                                                            SetTemplateData(data);

                                                                            var template_name = $('#template option:selected').text();
                                                                            SetTemplateName(template_name);


                                                                        }
                                                                        catch(err){

                                                                        }



                                                                    },
                                                                    error: function(XMLHttpRequest, textStatus, errorThrown) {



                                                                    },
                                                                    timeout:60000
                                                                });




                                                            }}
                                                            trigger={
                                                                <span className="eye_ico_wrapper">
                                                <EyeIco />
                                                        </span>

                                                            }
                                                            modal
                                                            nested
                                                        >
                                                            {close => (
                                                                <div className="inmodal">
                                                                    <button className="close" onClick={close}>
                                                                        &times;
                                                                    </button>
                                                                    <div className="header">Template Preview {template_name ? ": "+template_name : ""}</div>
                                                                    <div className="content ask_response_contents">


                                                                        {template_data ?

                                                                         <div className="group_row nofloat">
                                                                             {template_data?.map((item,index) => (
                                                                             <div key={index} className="template_data_row group_title">
                                                                            <span className="catname"><span className={"group_ico ico "+item.icon}></span>
<span className="txt"> {item.group_name}
    {item.is_editable=="1" ? <span className="newrow">Editable</span> : ""}
</span>
                                                                                <span className="total_questions">

                                                                                {item.questions_short_form?.map((question,index) => (
                <span className={"question"+index==0 ? " first" : ""}>
                    {index>0 ? ", " : ""}{question.adtest_title}
                </span>

                                                                                    ))}
                                                                                </span>


                                                                                    </span>
                                                            </div>

                                                                         ))
                                                                         }


                                                                         </div>
                                                                            :
                                                                        <PurpleLoader />

                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                        : ""}
                                                </label>
                                                <select

                                                    aria-invalid={errors.template ? "true" : "false"} {...register('template', {    validate:function(value){if(!$('#template').val() || $('#template').val()=="0" ) return 'no_value';}})}

                                                    onChange={(e) => {



                                                        TemplateIdSet(e.target.value);



                                                    }}
                                                    name="template" id="template">
                                                    {templates.map((item) => (
                                                        <option key={item.sid} value={item.sid}>{item.surveyls_title}</option>
                                                    ))
                                                    }

                                                </select>


                                                {errors.template && <span
                                                    className="error_span">Template is required</span>}


                                            </div>
                                            <div className="name_type">
                               <div className="label_wrapper">
                                   <label id="name_type_label" htmlFor="name_type" aria-invalid={errors.opponent_name_type ? "true" : "false"}>
                                   </label>

                                                <input  {...register('name_type', { required: true})} name="name_type" id="name_type" type="text" aria-invalid={errors.name_type ? "true" : "false"}/>


                                   {errors.name_type && <span
                                       className="error_span">{$('#category').val()=="1" ? "Candidate" : "Brand"} Name is required</span>}

                               </div>


                                            </div>


                                            <div className="opponent_name_type">
                                                <div className="label_wrapper">
                                                    <label id="opponent_name_type_label" htmlFor="opponent_name_type" aria-invalid={errors.opponent_name_type ? "true" : "false"}>
                                                    Opponent Name
                                                    </label>


                                                    <input  {...register('opponent_name_type', {    validate:function(value){

                                                        if(

                                                            (
                                                                $('#category').val()=="1"
                                                                &&
                                                                $('#opponent_name_type').val()==""
                                                            )




                                                        ) return 'Please Select an opponent.'}})}  name="opponent_name_type" id="opponent_name_type" type="text" aria-invalid={errors.opponent_name_type ? "true" : "false"}/>


                                                    {errors.opponent_name_type && <span
                                                        className="error_span">Opponent Name is required</span>}

                                                </div>


                                            </div>




                                        </div>
     </div>
         : ""}


                                        {!data.does_survey_have_responses ?
                                            <div className="media_type">
                                                <h4><span>Type of media to test</span></h4>

                                                <div className="media_options">


                                                                                            <span className="med_opt">
                                                <input checked={med_opt=="embed"} {...register('med_opt', { required: true})} value="embed" onChange={set_med_opt} type="radio" name="med_opt" id="med_embed" /> <label htmlFor="med_embed">Embeded(YouTube or Vimeo)</label>
                                            </span>

                                                    <span className="med_opt">
                                                <input checked={med_opt=="media"} {...register('med_opt', { required: true})} value="media" onChange={set_med_opt} type="radio" name="med_opt" id="med_media" /> <label htmlFor="med_media">Media(Image or Video)</label>
                                            </span>


                                                    <span className="med_opt">
                                                <input
                                                    onClick={(e) => {
                                                        SetLeadText("");
                                                    }}
                                                    checked={med_opt=="none"} {...register('med_opt', { required: true})} value="none" onChange={set_med_opt} type="radio" name="med_opt" id="med_none" /> <label
                                                        htmlFor="med_none">Message</label>
                                            </span>
                                                    {errors.med_opt && errors.med_opt.type === "required" && <div
                                                        className="error_span">Please select media type</div>}

                                                </div>
                                                <div className={"media_contents "+med_opt}>






                                                    <label aria-invalid={errors["lead_text"] ? "true" : "false"} htmlFor={"lead_text"}>Intro Text</label>



                                                    <div>
                                                    <textarea
                                                        {...register('lead_text', {    validate:function(value){

                                                                if(!$('#lead_text').val()){
                                                                    return 'lead_text_required';
                                                                }

                                                            }})

                                                        }

                                                        value={lead_text}
                                                        onChange={(e) => {



                                                            set_lead_text(e.target.value);

                                                            // valueset_media_content_with_name_at_idx("lead_text",index,e.target.value);

                                                            trigger('lead_text');


                                                        }}
                                                        id={"lead_text"} name="lead_text"  aria-invalid={errors["lead_text"] ? "true" : "false"}></textarea>
                                                        {errors["lead_text"] && <div
                                                            className="error_span">Please Enter Intro Text</div>}



                                                    </div>




                                                    {media_contents.map((item,index) => (
                                                        <div key={"media_row"+index} className={"media_content_row media_content_row"+index}>
<div className="clear"></div>

    <span
        onClick={(e) => {


            if(media_contents.length>4) {

                alert("Maximum Number of Adverts Allowed is 5.");
                return false;

            }

            var new_media_contents = [...media_contents];

            new_media_contents.push(get_base_media_empty_content());


            SetMediaContents(new_media_contents);


        }}
        className="add_ico_with_txt">

        <span

            className="quota_delete_add_ico add_question_icon"></span>
        <span className="txtarso">
Extra Advert(+{data.currency_symbol}75)
        </span>
    </span>

                                                            {media_contents.length>1 ?
                                                                <span

                                                                    onClick={(e) => {

                                                                        var new_media_contents = [];

                                                                        var item_index = 0;

                                                                        media_contents.forEach(function(media_item) {

                                                                            if(item_index++!==index){
                                                                                new_media_contents.push(media_item);
                                                                            }

                                                                        });

                                                                        SetMediaContents(new_media_contents);



                                                                    }}
                                                                    className="quota_delete_add_ico del_question_icon"></span>
                                                                : <span></span>}

                                                            <div className="label_wrapper advert_title">
                                                                <label aria-invalid={errors["advert_title"+index] ? "true" : "false"}>{advert_num(index)}</label>


                                                            </div>

                                                            <div className="label_wrapper text_above_image">

                                                                <label htmlFor={"advert_title"+index} className="one_liner sml">Title</label>
                                                                <input
                                                                    placeholder="Enter a memorable title"
                                                                    {...register('advert_title'+index, {    validate:function(value){

                                                                            if(!$('#advert_title'+index).val()){
                                                                                return 'advert_title_required';
                                                                            }


                                                                            var my_title = $('#advert_title'+index).val();

                                                                            var found_dup = false;
                                                                            $('.advert_title').each(function(){


                                                                                var this_title = $(this).val();
                                                                                console.log("check this from : "+index+" ttl : "+my_title+" to "+this_title);

                                                                                var this_idx = parseInt($(this).attr("data-advert-index"));


                                                                                if(this_title===my_title && this_idx!==index){

                                                                                    found_dup = true;
                                                                                }


                                                                            });

                                                                            if(found_dup){
                                                                                return "duplicate_value";
                                                                            }

                                                                        }})

                                                                    }





                                                                    value={item.advert_title}

                                                                    onChange={(e) => {


                                                                        set_media_content_with_name_at_idx("advert_title",index,e.target.value);

                                                                        trigger('advert_title'+index);


                                                                    }}
                                                                    data-advert-index={index}
                                                                    id={"advert_title"+index} className="advert_title" type="text" name={"adverts["+index+"][advert_title]"} />

                                                                {errors["advert_title"+index] && <div
                                                                    className="error_span left">Please Enter A Unique Advert Title</div>}





                                                                {med_opt=="none" ?
                                                                    <div className="msg_textor">
                                                                        <label className="one_liner sml" aria-invalid={errors.lead_text ? "true" : "false"} htmlFor={"end_text"+index}>Text</label>



                                                                        <div>
                                                    <textarea
                                                        placeholder="Enter text to test"
                                                        {...register('end_text'+index, {    validate:function(value){
                                                                if(!$('#end_text'+index).val()){
                                                                    return 'end_text_required';
                                                                }

                                                            }})

                                                        }

                                                        value={item.end_text}
                                className="paste_url"
                                                        onChange={(e) => {


                                                            set_media_content_with_name_at_idx("end_text",index,e.target.value);

                                                            trigger('end_text'+index);


                                                        }}


                                                        id={"end_text"+index} name={"adverts["+index+"][end_text]"}  aria-invalid={errors.end_text ? "true" : "false"}></textarea>
                                                                            {errors["end_text"+index] && <div
                                                                                className="error_span align_left">Please Enter End Text</div>}



                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div></div>

                                                                }



                                                            </div>


                                                            {med_opt == "media" ?
                                                                <div>
                                                                    <input type="hidden" name={"adverts["+index+"][muf_id]"} id={"muf_id"+index} value={item.uploaded_file_data.muf_id} />

                                                                    <div className="file_upload_selected" id="file_uploader">
                                                                        <div className="label_wrapper position_relative">

                                                                            <label className="one_liner" htmlFor={"upload_file"+index}>Select File
                                                                                {item.uploaded_file_data.muf_id  ?
                                                                                    <Popup
                                                                                        trigger={
                                                                                            <span className="eye_ico_wrapper">

                                                <EyeIco />
                                                        </span>

                                                                                        }
                                                                                        modal
                                                                                        nested
                                                                                    >
                                                                                        {close => (
                                                                                            <div className="inmodal">
                                                                                                <button className="close" onClick={close}>
                                                                                                    &times;
                                                                                                </button>
                                                                                                <div className="header">Media Preview </div>
                                                                                                <div className="content">

                                                                                                    {
                                                                                                        item.uploaded_file_data.file_type=='image' ?
                                                                                                            <div dangerouslySetInnerHTML={{__html: '<img src="'+item.uploaded_file_data.url+'" />'}} ></div> : ""
                                                                                                    }

                                                                                                    {
                                                                                                        item.uploaded_file_data.file_type=='video' ?
                                                                                                            <div dangerouslySetInnerHTML={{__html: `<video width="100%" height="440" controls >
                                                                                            <source src="`+item.uploaded_file_data.url+`" type="video/mp4">
                                                                                        </video>`}} ></div> : ""
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Popup>
                                                                                    : ""}
                                                                            </label>


                                                                            {is_file_being_uploaded && uploading_file_index==index ?
                                                                                <span className="file_upload_think"><PurpleLoader height={40} /></span> : ''}


                                                                            <input {...register('file_upload'+index, {    validate:function(value){if($('input[name="med_opt"]:checked').val() && $('input[name="med_opt"]:checked').val()=='media' && (


                                                                                    (
                                                                                        !item.uploaded_file_data.muf_id
                                                                                        &&
                                                                                        !$('#file_upload'+index).val()
                                                                                    )

                                                                                    ||
                                                                                    !$('#muf_id'+index).val()



                                                                                )) return 'xxxPlease Select a file.'}})} name={"file_upload"+index}
                                                                                   onChange={(e) => {

                                                                                       upload_file(e,index);
                                                                                   }}
                                                                                   disabled={is_file_being_uploaded}
                                                                                   className="file_upload"
                                                                                   id={"file_upload"+index} type="file" />




                                                                            {errors.upload_file && <span
                                                                                className="error_span">File is required</span>}
                                                                            <div className="clear"></div>
                                                                        </div>




                                                                    </div>
                                                                </div>
                                                                : <div></div> }

                                                            {med_opt == "embed" ?
                                                                <div className="msg_textor">
                                                                    <div className="label_wrapper text_embed">
                                                                        <input type="hidden" name={"adverts["+index+"][embed_videoid]"} value={embed_videoid} />
                                                                        <label
                                                                            className="urlabel"
                                                                            aria-invalid={errors["end_text"+index] ? "true" : "false"}
                                                                            htmlFor={"end_text"+index}>URL
                                                                            {item.end_text && !errors["end_text"+index] ?
                                                                                <Popup
                                                                                    trigger={
                                                                                        <span className="eye_ico_wrapper">

                                                <EyeIco />
                                                        </span>

                                                                                    }
                                                                                    modal
                                                                                    nested
                                                                                >
                                                                                    {close => (
                                                                                        <div className="inmodal">
                                                                                            <button className="close" onClick={close}>
                                                                                                &times;
                                                                                            </button>
                                                                                            <div className="header">Embed Preview </div>
                                                                                            <div className="content">

                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={{__html: get_embed_from_text(item.end_text)}}
                                                                                                ></div>


                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Popup>
                                                                                : ""}

                                                                        </label>
                                                                        <div>
                                                    <textarea className="paste_url" placeholder="Paste Your URL"
                                                        aria-invalid={errors["end_text"+index] ? "true" : "false"}

                                                        {...register('end_text'+index, {validate:function(value){



                                                                    console.log(med_opt);
                                                                    console.log("embedsa");




                                                                    if(med_opt && med_opt=='embed'

                                                                        && (
                                                                            !$('#end_text'+index).val()

                                                                            ||

                                                                            (
                                                                                !validate_youtube_embed($('#end_text'+index).val())

                                                                                &&

                                                                                !validate_vimeo_embed($('#end_text'+index).val())



                                                                            )

                                                                            ||
                                                                            !is_string_valid_url($('#end_text'+index).val())


                                                                        )
                                                                    ){

                                                                        return 'error';

                                                                    }

                                                                }
                                                            }
                                                        )}
                                                        value={item.end_text}
                                                        onChange={(e) => {

                                                            var url_entered = e.target.value;

                                                            if(!is_string_valid_url(url_entered)
                                                            ) {
                                                                console.log("not string");

                                                                var vimeo_validate = validate_vimeo_embed(url_entered);

                                                                if(vimeo_validate) {


                                                                    var vimeo_id = vimeo_validate[4];


                                                                    url_entered = "https://player.vimeo.com/video/"+vimeo_id;
                                                                }


                                                                var youtube_validate = validate_youtube_embed(url_entered);


                                                                if(youtube_validate) {


                                                                    var youtube_id = youtube_validate[8];

                                                                    url_entered = "https://www.youtube.com/embed/"+youtube_id;


                                                                }

                                                            }

                                                            set_media_content_with_name_at_idx("end_text",index,url_entered);
                                                            trigger("end_text"+index);
                                                            // item.end_text = (url_entered);

                                                        }}

                                                        name={"adverts["+index+"][end_text]"} id={"end_text"+index} cols="30" rows="10">

                                                    </textarea>
<div className="clear"></div>
                                                                            {errors["end_text"+index] && <div
                                                                                className="error_span align_left"><div className="clear"></div>Please enter a valid embed code. Note youtube does not support embedding shorts.</div>}


                                                                        </div>

                                                                    </div>
                                                                </div> : <div></div> }
<div className="clear"></div>
                                                        </div>
                                                    ))}



                                                </div>


                                            </div>
                                            : ""}

                                        <div className="number_of_completes">
                                            <div className="label_wrapper">
                                                <label htmlFor="number_of_completes">Number of completes</label>
                                                <select value={number_of_completes} onChange={set_number_of_completes} name="number_of_completes" id="number_of_completes">
                                                    {completes_options.map((item) => (
                                                        <option key={item.appo_id} value={item.num_completes}>{item.num_completes}</option>
                                                    ))
                                                    }
                                                </select>

                                            {project_id ? <span className="explain">*Changing number of completes can effect pricing.</span> : ""}
                                            </div>


                                        </div>



                                        {!project_id ?

                                        <div className="verification_type">
                                            <h4><span>Verification type</span></h4>

                                            <div className="verification_options">

                                            <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})}  onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" value="email" id="ver_email" /> <label htmlFor="ver_email">Email</label>
                                            </span>

                                                <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})} onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" id="ver_sms" value="sms" /> <label htmlFor="ver_sms">SMS</label>
                                            </span>

                                                <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})} onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" id="ver_none" value="" /> <label
                                                    htmlFor="ver_none">None</label>
                                            </span>


                                                {errors.ver_opt && errors.ver_opt.type === "required" && <div
                                                    className="error_span">Please select verification type</div>}


                                            </div>
                                        </div>

                                            : ""}
                                        {
                                            project_id ?
                                        <Calculator title="Additional Cost" product_price={product_price} display="true" />
                                                :
                                        <Calculator product_price={product_price} display="true" />


                                        }


                                        <div className="submitor">
                                            {is_submitting ?
                                                <PurpleLoader height={40} /> : ''}
                                            <input disabled={is_submitting} type="submit" value="Continue Setup" />


                                        </div>
                                        <div id="more_spacing"></div>

                                    </form>
                                    {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}


                                    <div className="clear"></div>
                                </div>



                            )}



                    </div>
                </div>
            </div>

        </div>
    );
};
export default  CreateProject